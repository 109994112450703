import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => (
  <Layout>
    <SEO title="Contact" />
    <div id="contact">
      <h1>The Form.</h1>
      <p>
        Like the site? Need a site? Found a typo? Need a friend? Whatever the
        reason, feel free to reach out! I'll see what I can do. I am always interested in working on new projects.
      </p>

      <hr />

      <form
        name="aj-contact-form"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="dgjkldthni3u80"
        action="/formsent"
      >
        <input type="hidden" name="dgjkldthni3u80" />
        <input type="hidden" name="form-name" value="aj-contact-form" />
        <p className="small">All fields are required.</p>
        <div className="form-group">
          <label htmlFor="name">name</label>
          <input
            type="text"
            name="name"
            placeholder="enter your name"
            required
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="email">email</label>
          <input
            type="email"
            name="email"
            placeholder="enter your email address"
            required
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="comment">comment</label>
          <textarea
            name="comment"
            rows="10"
            placeholder="enter your message"
            required
          />
        </div>
        <div data-netlify-captcha></div>
        <div className="buttons">
          <input className="btn" type="submit" value="send" />
          <input className="btn btn-2" type="reset" value="clear" />
        </div>
      </form>
    </div>
  </Layout>
)

export default AboutPage
